@mixin random-background() {
  background: rgba(random(255), random(255), random(255), 1);
}

@mixin transition-fix() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0)
}

@mixin transition-animate {
  @for $i from 1 through 50 {
    &:nth-of-type(#{$i}) {
      transition: all .2s #{$i * .1}s ease-in;
      animation-delay: #{$i * .1}s;
    }
  }
}

@mixin absolute-content {
  content: '';
  display: block;
  position: absolute;
}
