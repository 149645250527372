@import 'src/styles/colors.module';

.mycrew-team-overview {
  height: 100%;
  min-height: 100%;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    border-bottom: 1px solid rgba($color-off-white-tint, 1);
    font-size: 0;
    position: relative;

    &:last-child {
      border-bottom: none;
    }
  }

  &__list-link {
    align-items: center;
    color: $color-white;
    display: grid;
    font-size: 20px;
    font-weight: 500;
    grid-template-columns: 1fr 24px;
    justify-content: center;
    line-height: 1.5em;
    padding: 15px;
    position: relative;

    &--selected {
      background: $color-background;
      font-weight: 700;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__list-arrow {
    font-size: 0;
    line-height: 0;
    text-align: center;
  }
}
