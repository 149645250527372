@import 'src/styles/colors.module';

.mycrew-team-user-menu {
  height: 100%;
  min-height: 100%;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    border-bottom: 1px solid rgba($color-off-white-tint, 1);
    font-size: 0;
    position: relative;

    &:last-child {
      border-bottom: none;
      border-top: 1px solid rgba($color-off-white-tint, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  &__list-link {
    align-items: center;
    color: $color-white;
    display: grid;
    font-size: 20px;
    font-weight: 500;
    grid-template-columns: 24px 1fr;
    grid-column-gap: 15px;
    justify-content: center;
    line-height: 1.5em;
    padding: 15px;
    position: relative;

    &--selected {
      background: $color-background;
      font-weight: 700;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__icon {
    font-size: 0;
    line-height: 0;
    text-align: center;
  }
}

// Light theme for UserMenu
.mycrew-light {
  .mycrew-team-user-menu {
    &__list-item {
      border-bottom: 1px solid rgba($color-light-text, 1);

      &:last-child {
        border-top: 1px solid rgba($color-light-text, 1);
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__list-link {
      color: $color-light-text;

      &--selected {
        background: $color-light-text;
      }
    }
  }

}