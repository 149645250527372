@import 'src/styles/colors.module';

.mycrew-overlay-header {
  align-items: center;
  border-bottom: 1px solid $color-off-white;
  display: grid;
  grid-template-columns: 1fr 48px;
  justify-content: center;
  margin: 0;
  min-height: 48px;
  padding: 15px 0 0 15px;

  &__title {
    font-weight: 100;
    letter-spacing: 0.05em;
    line-height: 1em;
    margin: 0;
    padding: 0;
  }

  &__close {
    font-size: 0;
    text-align: center;
    width: 100%;
  }

  &__close-icon {
    color: $color-white;
  }
}

// Light theme for OverlayHeader
.mycrew-light {
  .mycrew-overlay-header {
    border-bottom: 1px solid $color-light-text;

    &__close-icon {
      color: $color-light-text;
    }
  }
}
