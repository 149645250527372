@import 'src/styles/colors.module';

.modal-loader {
  background: rgba($color-white, 0.75);
  border-radius: 15px;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  &__content {
    //margin-top: 50%;
    //transform: translateY(-50%);
    margin-top: calc(50% - 35px);
  }

  &__spinner {
    transform: scale(1.5);
  }

  &__title {
    color: $color-black;
    letter-spacing: 1px;
    margin-top: 15px;
  }
}
