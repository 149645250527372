@import 'src/styles/colors.module';

.mycrew-team-page {
  &__title {
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    color: $color-white;

    & span {
      background: $color-background-inner;
      padding: 0 15px;
      position: relative;
      z-index: 2;
    }

    &:before {
      border-top: 1px solid $color-off-white-tint;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      width: 100%;
      z-index: 1;
    }
  }

  &__about {}

  &__members {
    &-title {
      font-size: 1.1em;
      font-weight: 300;
      margin: 0;
      padding: 0 0 15px 0;
      text-align: center;
    }

    &-list {
      @media screen and (min-width: 480px) {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 25px 15px;
      }

      align-items: stretch;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      margin: 0;
      padding: 0;
    }

    &-item {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    &-person {
      @media screen and (min-width: 480px) {
        padding: 20px 0 10px 0;
      }

      background: $color-background;
      border-radius: 5px;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      min-height: 100%;
      overflow: hidden;
      padding: 10px 0;
      position: relative;
      text-align: center;
      transition: all 50ms linear;
      vertical-align: top;
      width: 100%;

      &:hover {
        box-shadow: 0 0 1px 2px rgba($color-background-gradient, 1);
      }

      &:last-child {
        margin-right: 0;
      }

      &-photo {
        display: block;

        &--image {
          background: rgba($color-white, 0.3);
          border-radius: 7px;
          box-shadow: 0 0 0 1px rgba($color-background-gradient, 0.8);
          display: inline-block;
          height: 120px;
          overflow: hidden;
          width: 120px;
          position: relative;

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            background: red;
          }
        }
      }

      &-position {
        background: $color-background-gradient;
        border-radius: 3px;
        box-shadow: 1px 1px 0 1px rgba($color-black, 0.15);
        color: $color-white;
        display: block;
        font-size: 0.75em;
        font-weight: 700;
        margin: -12px 10px 0 10px;
        padding: 3px 7px;
        position: relative;

      }

      &-name {
        color: $color-white;
        font-weight: 500;
        margin-top: 20px;
        line-height: 1.2em;
        padding-bottom: 15px;

        & strong {
          color: $color-white;
          display: block;
          font-size: 1.1em;
          letter-spacing: 1px;
          padding-bottom: 5px;
        }
      }
    }
  }
}

body.mycrew-light {
  .mycrew-team-page {
    &__title {
      & span {
        background: $color-light-background;
      }

      &:before {
        border-top: 1px solid $color-light-text-accent;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        top: 50%;
        width: 100%;
        z-index: 1;
      }
    }

    &__about {}

    &__members {
      &-person {
        background: $color-switch-toggle-yellow;

        &:hover {
          box-shadow: 0 0 1px 2px rgba($color-switch-toggle, 0.5);
        }

        &-photo {
          &--image {
            box-shadow: 0 0 0 1px rgba($color-light-background, 0.8);
          }
        }

        &-position {
          background: $color-switch-toggle;
          box-shadow: 1px 1px 0 1px rgba($color-light-text, 0.15);
        }
      }
    }
  }
}
