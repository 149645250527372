.mycrew-header {
  @media screen and (min-width: 480px) {
    margin-bottom: 30px;
  }

  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 15px;
  z-index: 2;
  position: relative;
}
