@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import 'src/styles/colors.module';
@import 'src/styles/mixins';

.mycrew-footer {
  @media screen and (min-width: 480px) {
    margin-top: 15px;
    padding-bottom: 0;
  }

  color: rgba($color-text, 0.3);
  display: grid;
  font-size: 0.84em;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  padding-bottom: 15px;

  &__copy {
    letter-spacing: 0.05em;

    & .brand {
      color: $color-background;
      letter-spacing: 0.05em;
      font-family: 'Bungee', cursive;

      &-letter {
        @include transition-animate;

        animation-name: textocolor;
        animation-duration: 10s;
        animation-iteration-count: infinite;
      }
    }
  }

  &__support {
    text-align: right;

    & &--link {
      cursor: pointer;
      letter-spacing: 0.05em;
      color: rgba($color-text, 0.2);

      &:hover {
        color: rgba($color-text, 0.8);
      }
    }
  }
}

.mycrew.mycrew-light .mycrew-footer {
  color: rgba($color-light-text, 0.84);

  &__copy {
    & .brand {
      color: $color-light-text;

      &-letter {
        @include transition-animate;

        animation-name: textocolor-light;
        animation-duration: 10s;
        animation-iteration-count: infinite;
      }
    }
  }

  &__support {
    &--link {
      color: rgba($color-light-text, 0.6);

      &:hover {
        color: $color-light-text;
      }
    }
  }
}

@keyframes textocolor {
  0% {
    color: $color-background;
    box-shadow: none;
  }
  0.3% {
    box-shadow: 2px 2px 1px 1px $color-background-gradient-super;
    color: rgba($color-white, 0.5);
  }
  1% {
    box-shadow: 2px 2px 0 0 $color-background-gradient;
    color: $color-background-gradient;
  }
  2.5% {
    color: $color-background;
  }
  3% {
    box-shadow: none;
  }
  100% {
    color: $color-background;
  }
}

@keyframes textocolor-light {
  0% {
    color: $color-light-text;
    box-shadow: none;
  }
  0.3% {
    box-shadow: 2px 2px 1px 1px $color-background-gradient-super;
    color: rgba($color-white, 0.5);
  }
  1% {
    box-shadow: 2px 2px 0 0 $color-light-text-accent;
    color: $color-light-text-accent;
  }
  2.5% {
    color: $color-light-text;
  }
  3% {
    box-shadow: none;
  }
  100% {
    color: $color-light-text;
  }
}

