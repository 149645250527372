@import 'src/styles/colors.module';

.mycrew-overlay-footer {
  padding: 5px;

  &__close.MuiButtonBase-root {
    background: $color-background;
    color: $color-white;
    font-weight: 700;
    letter-spacing: 0.1em;
    padding: 12px 0;
    width: 100%;
  }

  &__close-icon {}
}

// Light theme for OverlayFooter
.mycrew-light {
  .mycrew-overlay-footer {
    &__close.MuiButtonBase-root {
      background: $color-light-background;
      border: 1px solid $color-light-text;
      color: $color-light-text;
    }
  }
}
