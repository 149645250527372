@import 'src/styles/colors.module';

.mycrew-login-page {
  @media screen and (min-width: 480px) {
    display: grid;
    margin: -30px;
    grid-template-rows: 1fr 35px;
    grid-template-columns: 280px 1fr 1fr;
  }

  height: 100%;
  min-height: 100%;

  &__panel {
    @media screen and (min-width: 480px) {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    position: relative;

    &--top {
      @media screen and (min-width: 480px) {
        height: auto;
      }

      height: 70%;
      overflow: auto;
      position: relative;
    }

    &--bottom {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 30%;
      justify-content: center;
      text-align: center;
    }
  }

  &__panel-promo {
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-row-start: 1;
    height: 640px;
    overflow: hidden;
    padding: 0 30px;
    text-align: left;
  }

  &__panel-brand {
    grid-column-end: 3;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-row-start: 1;
  }

  &__panel-sso {
    grid-column-end: 4;
    grid-column-start: 3;
    grid-row-end: 2;
    grid-row-start: 1;
    position: relative;

    &:before {
      border-left: 1px solid $color-background;
      bottom: 55px;
      content: '';
      left: 0;
      position: absolute;
      top: 80px;
    }
  }

  &__panel-copy {
    grid-column-end: 4;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-row-start: 2;
  }

  &__panel-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
  }

  &__promo-title,
  &__promo-text,
  &__promo-text-follow {
    margin: 0;
    position: relative;
    text-align: left;
    z-index: 2;
  }

  &__promo-title {
    font-size: 42px;
    font-weight: 700;
    line-height: 1.1em;
    margin-top: 50px;
    padding: 0 0 45px 0;
    text-shadow: 1px 1px 0 rgba($color-background-inner, 0.6);
  }

  &__promo-text,
  &__promo-text-follow {}

  &__promo-text-follow {
    margin-top: 15px;
  }

  &__promo-text-content {
    background: rgba($color-background-inner, 0.64);
    box-decoration-break: clone;
    box-sizing: border-box;
    display: inline;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 1.65em;
    margin: 0;
    padding: 0 5px;
    text-align: left;
    text-shadow: 1px 1px 0 rgba($color-background-inner, 0.6);
  }

  &__promo-image {
    left: 0;
    opacity: 0.42;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &__graphics {
    @media screen and (min-width: 480px) {
      margin-top: 200px;
    }

    font-size: 0;
    text-align: center;
  }

  &__customer {
    @media screen and (min-width: 480px) {
      margin: 180px 0 45px;
    }

    color: $color-brand-primary;
    font-size: 2.25em;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  &__button.MuiButton-contained {
    background: $color-background-gradient;
    background-image: -webkit-linear-gradient(to right, $color-background-gradient, $color-background-inner-gradient);
    background-image: linear-gradient(to right, $color-background-gradient, $color-background-inner-gradient);
    background-size: 150% auto;
    border-radius: 30px;
    color: white;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    padding: 15px 45px;
    text-transform: none;
    transition: padding 150ms ease-in-out;

    &:hover {
      padding: 15px 48px;
    }
  }


  &__note {
    @media screen and (min-width: 480px) {
      padding: 30px 0 0 0;
    }

    color: $color-background;
    display: block;
    margin: 0;
    padding: 15px 0 0 0;

    & > &--icon {
      height: 18px;
      margin-left: 3px;
      vertical-align: -2px;
    }
  }

  &__copyright {
    @media screen and (min-width: 480px) {
      display: inline-block;
      padding: 0;
    }

    color: $color-off-white-tint;
    display: block;
    font-size: 0.69em;
    margin: 0;
    padding: 45px 0 0 0;
  }
}
