@import 'src/styles/colors.module';
@import 'src/styles/common';

.mycrew-directory-page {
  height: $directory-height;

  &__link {
    height: auto;
    overflow: auto;
    position: relative;
    width: 100%;

    &:before {
      border-radius: $directory-gap;
      border: $placeholder-border dashed rgba($color-background, 0.5);
      content: '';
      height: calc(100% - (#{$placeholder-border} * 2));
      position: absolute;
      width: calc(100% - (#{$placeholder-border} * 2));
      z-index: 0;
    }

    & img {
      position: relative;
      z-index: 1;
    }

    & span {
      align-items: flex-end;
      background: rgba($color-brand-primary, 0.5);
      border-radius: $directory-gap;
      bottom: 0;
      color: $color-text;
      display: none;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      z-index: 2;

    & strong {
        transform: translateY(-15px);
      }
    }

    &:hover span,
    &:focus span {
      opacity: 1;
      display: flex;
    }
  }
}


.item-loading {
  @keyframes placeHolderShimmer {
    0% {
      background-position: -30vw 0
    }
    100% {
      background-position: 70vw 0
    }
  }

  & .animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $color-text;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 50px;
    -webkit-backface-visibility: hidden
  }
}

.bflex {
  display: grid;
  font-size: 0;
  grid-column-gap: $directory-gap;
  grid-row-gap: $directory-gap;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;

  &-item {
    align-items: center;
    border-radius: $directory-gap;
    display: flex;
    font-size: 0;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
  }
}

.size-large {
  flex-basis: calc(#{$directory-height} - #{$directory-gap});
  font-size: 0;
  grid-column: 1/3;
  grid-row: 1/3;
  height: $directory-height;

  & strong {
    font-size: 1.2em;
  }
}

.size-medium {
  flex-basis: calc(#{$directory-height} / 2 - #{$directory-gap});
  font-size: 0;
  height: $directory-height;
}

.size-mini {
  @media only screen and (min-width: 480px) {
    border-radius: $directory-gap;
  }

  border-radius: calc(#{$directory-gap} / 2);
  display: flex;
  flex-basis: calc(#{$directory-height} / 2 - #{$directory-gap});
  flex-wrap: wrap;
  font-size: 0;
  height: $directory-height;
}

.size-micro {
  @media only screen and (min-width: 480px) {
    border-radius: $directory-gap;
    flex-basis: calc(#{$directory-height} / 2 - #{$directory-gap} / 3);
    height: calc(#{$directory-height} / 2 - #{$directory-gap} / 3);

    &:nth-child(2n+2) {
      margin-left: 10px;
    }

    &:nth-child(-n+2) {
      margin-bottom: 10.5px;
    }
  }

  border-radius: calc(#{$directory-gap} / 2);
  flex-basis: 50%;
  font-size: 0;
  height: $directory-height;
  width: 100%;

  & strong {
    font-size: 0.8em;
  }

  .mycrew-directory-page__link:before {
    border-radius: calc(#{$directory-gap} / 2);
  }
}

@media only screen and (max-width: 1024px) {
  .page-container {
    width: 768px;
    margin: 0 auto;
  }

  .bflex {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .page-container {
    width: 480px;
    margin: 0 auto;
  }

  .bflex {
    grid-template-columns: 1fr 1fr;
  }

  .size-micro {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .page-container {
    margin: 0 auto;
  }

  .bflex {
    grid-template-columns: 1fr 1fr;
  }

  .size-large,
  .size-medium,
  .size-mini {
    text-align: left;
    height: $directory-height;
  }

  .size-mini {
    display: grid;
    font-size: 0;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
}

.mycrew.mycrew-light .mycrew-directory-page__link:before {
  border-color: $color-light-text;
}
