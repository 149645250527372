@import 'src/styles/colors.module';

.photo-add {
  &:hover {
    .photo-add__drop {
      border: 3px dashed rgba($color-off-white-tint, 1);

      &--dragging {
        border: 3px dashed rgba($color-off-white-tint, 1);
      }
    }

    .photo-add__drop-text {
      color: $color-off-white-tint;
    }
  }

  height: 300px;
  display: flex;
  justify-content: center;
  text-align: center;

  &__drop {
    align-items: center;
    border-radius: 17px;
    border: 3px dashed rgba($color-off-white, 0.5);
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    transform: scale(0.97);
    transition: all 150ms ease-in-out;
    width: 100%;

    &--dragging {
      border-radius: 9px;
      border: 3px dashed rgba($color-off-white, 0.75);
      transform: scale(1);
      transition: all 150ms ease-in-out;

      .photo-add__drop-text {
        font-size: 1em;
        color: rgba($color-off-white-tint, 9);
      }
    }
  }

  &__drop-text {
    color: rgba($color-off-white-tint, 0.75);
    font-weight: 600;
    font-size: 1.1em;
    transition: all 60ms ease-in-out;

    &--note {
      border-radius: 5px;
      border: 1px solid rgba($color-off-white, 0.5);
      bottom: 25px;
      display: block;
      font-size: 0.55em;
      font-weight: 500;
      left: 25px;
      padding: 10px 10px 10px 44px;
      position: absolute;
      right: 25px;
      text-align: left;

      &:before {
        background: rgba($color-off-white, 0.2);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 35px;
        transition: all ease-in-out 300ms;
      }

      &:hover {
        border: 1px solid rgba($color-off-white, 1);
        color: $color-background-inner;

        &:before {
          right: 0;
          width: 100%;
        }
      }
    }

    &--line {
      display: block;
    }

    &--note-icon {
      left: 5px;
      top: 13px;
      position: absolute;
    }
  }

  &__preview {
    border-radius: 35% 65% 90% 10% / 90% 65% 35% 10%;
    box-shadow: 5px 5px 0 0 rgba($color-off-white-tint, 0.75);
    -webkit-box-shadow: 5px 5px 0 0 rgba($color-off-white-tint, 0.75);
    height: 200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transition: all 450ms ease-in-out;
    width: 200px;

    &-photo {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__cropper {
    height: 300px;
    width: 300px;
    margin: 0 auto;

    .reactEasyCrop_Container {
      border-radius: 5px;
      border: 1px solid $color-background-inner;
    }
  }
}
