@import 'src/styles/fonts';
@import 'src/styles/colors.module';

html, body {
  @media screen and (min-width: 480px) {
    background: $color-background-gradient;
    background-image: -webkit-linear-gradient(to right, $color-background-gradient, $color-background-inner-gradient);
    background-image: linear-gradient(to right, $color-background-gradient, $color-background-inner-gradient);
  }

  background: $color-background-inner;
  height: 100%;
  min-height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
}

body {
  color: $color-text;
  height: 100%;
  margin: 0;
}

a {
  cursor: pointer;
  color: rgba($color-link, 0.65);
  font-weight: 700;

  &:hover {
    color: rgba($color-link, 0.8);
  }

  &:active {
    color: $color-link;
  }
}

body.mycrew-light {
  color: $color-light-text;
  background: $color-background-light-gradient;
  background-image: -webkit-linear-gradient(to right, $color-background-light-gradient, $color-background-light-inner-gradient);
  background-image: linear-gradient(to right, $color-background-light-gradient, $color-background-light-inner-gradient);

  a {
    color: $color-light-link;

    &:hover {
      color: rgba($color-light-link, 0.7);
    }

    &:active {
      color: $color-light-link;
    }
  }
}

#root {
  @media screen and (max-width: 480px) {
    height: 100%;
    min-height: 100%;
    overflow: auto;
  }
}

.mycrew {
  @media screen and (min-width: 480px) {
    border-radius: 15px;
    -webkit-box-shadow: 5px 10px 30px 3px rgba(0, 0, 0, 1);
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    margin: 30px auto;
    padding: 30px;
    width: 420px;
  }

  @media screen and (min-width: 768px) {
    width: 640px;
  }

  @media screen and (min-width: 980px) {
    width: 960px;
  }

  @media screen and (min-width: 1280px) {
    width: 1120px;
  }

  background: $color-background-inner;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  min-height: 100%;
  overflow: auto;
  padding: 15px 15px 70px 15px;
  position: relative;
  width: 100%;

  &.mycrew-light {
    background: $color-light-background;
  }

  &.login-ready {
    overflow: hidden;
    width: 960px;
  }
}

.mycrew-pages {
  @media screen and (min-width: 480px) {}
  position: relative;
  z-index: 1;
}

.loading, .loading-light {
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingVisor;
  animation-timing-function: linear;
  background: rgba($color-white, 0.3);
  background: linear-gradient(to right, rgba($color-white, 0.3) 8%, rgba($color-background, 1) 38%, rgba($color-white, 0.3) 54%);
  background-size: 640px 640px;

  position: relative;
}

.loading-light {
  animation-name: loadingVisorLight;
  background: rgba($color-white, 0.3);
  background: linear-gradient(to right, rgba($color-white, 0.3) 8%, rgba($color-background-light-gradient, 1) 38%, rgba($color-white, 0.3) 54%);
  background-size: 640px 640px;

  position: relative;
}

@keyframes loadingVisor {
  0% {
    background-position: -320px 0
  }
  100% {
    background-position: 320px 0
  }
}
@keyframes loadingVisorLight {}
