@import 'src/styles/colors.module';
@import 'src/styles/mixins';

$modal-bg-sub: 9px;
$modal-width: 320px;
$modal-width-desk: 360px;

.mycrew-modal {
  @media screen and (min-width: 480px) {
    left: calc(25% + (#{$modal-width-desk} / 4));
    position: absolute;
    width: $modal-width-desk;
    top: calc(25% - (#{$modal-width-desk} / 4));
  }

  background: $color-white;
  border-radius: 15px;
  -webkit-box-shadow: 0 15px 40px 10px rgba($color-black, 0.6);
  box-shadow: 0 15px 40px 10px rgba($color-black, 0.6);
  content: '';
  display: block;
  max-height: 550px;
  left: calc(50% - (#{$modal-width} / 2));
  position: fixed;
  top: calc(50% - (#{$modal-width} / 2));
  width: $modal-width;
  z-index: 100;
  transform-origin: center center;

  &:before {
    background: -webkit-linear-gradient(to top, $color-background-gradient, $color-background-inner-gradient);
    background: linear-gradient(to top, $color-background-gradient, $color-background-inner-gradient);
    border-radius: 20px;
    bottom: -$modal-bg-sub;
    content: '';
    left: -$modal-bg-sub;
    opacity: 1;
    position: absolute;
    right: -$modal-bg-sub;
    top: -$modal-bg-sub;
  }

  &--loading {
    animation: show-me 300ms forwards ease-out;
  }

  &--error {
    animation: error-shake 750ms ease-in-out;
  }

  &-curtain {
    @media screen and (min-width: 480px) {
      border-radius: 15px;
      position: absolute;
    }

    animation: blur-background 200ms forwards ease-in;
    bottom: 0;
    content: '';
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 50;
  }

  &__wrapper {
    background: $color-background-modal;
    border-radius: 15px;
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 100;
    padding: 30px;
    box-sizing: border-box;
  }

  &__header {}

  &__title {
    color: rgba($color-black, 0.7);
    font-size: 1.2em;
    font-weight: 700;
  }

  &__paragraph {
    color: rgba($color-black, 0.8);
    font-size: 0.95em;
  }

  &__close {
    outline: none;
    position: absolute;
    right: 15px;
    top: 15px;

    &:hover,
    &:focus {
      .mycrew-modal__close--icon {
        color: rgba($color-background-inner, 0.7);
      }
    }

    &:active {
      .mycrew-modal__close--icon {
        color: $color-background-inner;
      }
    }

    &--icon {
      color: rgba($color-background-inner, 0.3);
    }
  }

  &__content {
    margin-top: 15px;

    &-block {
      display: block;
      margin: 0 0 10px 0;
      padding-bottom: 10px;
      position: relative;

      &--new::before {
        @include absolute-content;

        animation: flash-on-load 500ms ease-in;
        bottom: -0px;
        left: -30px;
        right: -30px;
        top: -5px;
      }
    }

    &--error,
    &--success {
      .MuiSvgIcon-root {
        color: $color-gray-80;
        display: block;
        font-size: 5.5rem;
        margin: 0 auto;
      }

      p {
        color: $color-black;
        line-height: 2em;
        padding-bottom: 15px;
        text-align: center;
      }
    }

    &--success {
      .MuiSvgIcon-root {
        color: $color-green;
      }
    }

    & .dropdown {
      &-label {
        color: rgba($color-background-inner, 0.5);
        display: block;
        font-size: 0.84em;
        padding-bottom: 5px;
        position: relative;

        &__notice {
          color: $color-red;
          font-family: 'Courier New', monospace;
          font-size: 1.2em;
          font-weight: 700;
          line-height: 0.84em;
          position: absolute;
          top: 0;
          transform: translateX(50%);
        }

        &--error {
          color: $color-red;
          font-size: 11px;
          letter-spacing: 0.05em;
          position: absolute;
          right: 2px;
          top: 4px;
          z-index: 2;
        }
      }

      &-select {
        font-size: 0.9em;
        color: $color-black;

        &__control,
        &__option {
          cursor: pointer;
        }
      }

      &-input {
        width: 100%;

        & input {
          padding: 10px 15px 7px 15px;
          border: 1px solid $color-gray-80;
          border-radius: 4px;
          background: $color-white;

          &:focus {
            border-color: $color-blue-active;
            -webkit-box-shadow: 0 0 0 1px $color-blue-active;
            box-shadow: 0 0 0 1px $color-blue-active;
          }
        }

        &-error {
          & input {
            border: 1px solid $color-red;

            &:focus {
              border-color: $color-red;
              -webkit-box-shadow: 0 0 0 1px $color-red;
              box-shadow: 0 0 0 1px $color-red;
            }
          }
        }
      }
    }
  }

  &__footer {
    margin-top: 15px;
    text-align: right;

    &-notice {
      color: $color-red;
      font-size: 11px;
      letter-spacing: 0.5px;
      line-height: 1.5em;
      margin-bottom: 15px;
      text-align: center;
      background: rgba($color-white, 0.3);
      padding: 3px 0;
      border-radius: 3px;
      border: 1px solid rgba($color-white, 0.5);
    }

    & .dropdown {
      &-button {
        &__cancel {}
        &__primary,
        &__secondary {
          padding-left: 30px;
          padding-right: 30px;
          margin-left: 15px;
          background: $color-background;

          &:hover {
            background-color: rgba($color-background, 0.9);
          }
        }

        &__secondary {
          background: $color-red;

          &:hover {
            background-color: rgba($color-red, 0.9);
          }
        }
      }
    }
  }
}

@keyframes show-me {
  0% {
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
  }
  1% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blur-background {
  0% {
    backdrop-filter: none;
    background: rgba($color-background-inner, 0);
  }
  100% {
    backdrop-filter: blur(3px);
    background: rgba($color-background-inner, 0.6);
  }
}

@keyframes flash-on-load {
  0% {
    opacity: 0;
    background: rgba($color-yellow-nice, 0);
  }
  10% {
    opacity: 1;
  }
  30% {
    background: $color-yellow-nice;
  }
  100% {

    background: rgba($color-yellow-nice, 0);
  }
}

@keyframes error-shake {
  0% {
    transform: perspective(750px);
  }
  16% {
    transform: perspective(750px) rotate3d(0, -0.5, 0, 15deg);
  }
  32% {
    transform: perspective(750px) rotate3d(0, -0.5, 0, -12deg);
  }
  48% {
    transform: perspective(750px) rotate3d(0, -0.5, 0, 7deg);
  }
  64% {
    transform: perspective(750px) rotate3d(0, -0.5, 0, -5deg);
  }
  80% {
    transform: perspective(750px) rotate3d(0, -0.5, 0, 3deg);
  }
  100% {
    transform: perspective(750px) rotate3d(0, -0.5, 0, 0);
  }
}
