$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);
$color-off-white: rgb(162, 167, 171); // #A2A7AB
$color-off-white-tint: rgb(110, 116, 121); // #6E7479
$color-red: rgb(186, 38, 43);
$color-green: rgb(119, 192, 99); // #77c063
$color-light-green: rgb(48, 92, 38); // #305C26
$color-gray-80: rgb(204, 204, 204); // #cccccc
$color-gray-70: rgb(179, 179, 179); // #b3b3b3
$color-blue-active: rgb(38, 132, 255); // #2684ff
$color-yellow-nice: rgb(243, 209, 133); // #F3D185
$color-yellow-pale: rgb(233, 220, 210); // #E9DCD2
$color-background-modal: rgb(223, 225, 235); // #DFE1EB

// FROM LOGO DESIGN
$color-design-primary: rgba(0, 59, 79, 1); // #003b4f

$color-link: #6FB2F6; // #6FB2F6
$color-link-accent: #0577F6; // #0577F6

$color-text: $color-white;
$color-background: rgb(76, 76, 109); // #4c4c6d
$color-background-inner: rgb(42, 43, 63); // #2a2b3f

$color-background-gradient: rgb(102, 79, 159); // #664f9f
$color-background-inner-gradient: rgb(54, 73, 130); // #364982

// LIGHT THEME
$color-light-link: rgb(181, 16, 2); // #B51002
$color-light-text: rgb(173, 98, 25); // #AD6219
$color-light-text-accent: rgb(185, 111, 31); // #B96F1F
$color-light-background: rgb(242, 204, 119); // #F2CC77
$color-background-light-gradient: rgb(241, 206, 131); // #F1CE83
$color-background-light-inner-gradient: rgb(212, 145, 209); // #D491D1
$color-light-all-search: rgb(255, 229, 178); // #ffe5b2

$color-background-gradient-super: rgb(218, 204, 255); // #daccff

$color-brand-primary: rgb(95, 177, 68);
$color-brand-secondary: #00a039;

$color-switch-white: rgb(233, 229, 213); // #e9e5d5
$color-switch-toggle: rgb(218, 133, 8); // #DA8508
$color-switch-toggle-yellow: rgb(229, 169, 82); // #e5a952
$color-switch-toggle-night: rgb(28, 57, 88); // #1C3958
$color-switch-sun: rgb(255, 126, 0); // #ff7e00
$color-switch-moon: $color-background-gradient; // #F2EF88

:export {
  colorRed: $color-red;
  colorGreen: $color-green;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorBrandPrimary: $color-brand-primary;
  colorBrandSecondary: $color-brand-secondary;
  colorDesignPrimary: $color-design-primary;
  colorThemeDark: $color-background;
  colorThemeDarkAlt: $color-background-inner;
  colorThemeLight: $color-light-background;
  colorThemeLightAlt: $color-light-text-accent;
}
