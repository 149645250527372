@import 'src/styles/colors.module';

.mycrew-all-branding {
  @media screen and (min-width: 480px) {
    border-bottom: none;
    grid-template-columns: 48px 1fr;
    margin-bottom: 30px;
  }

  align-items: center;
  display: grid;
  grid-template-columns: 48px 1fr;
  justify-content: center;
  margin-bottom: 15px;

  &__logo {
    @media screen and (min-width: 480px) {
      transform: none;
    }

    border-radius: 5px;
    display: block;
    outline: none;
    position: relative;

    &:hover {
      opacity: 0.9;
    }

    &:active::after {
      background: $color-brand-secondary;
      border-radius: 5px;
      bottom: -5px;
      content: '';
      left: -5px;
      position: absolute;
      right: -5px;
      top: -5px;
      z-index: 1;
    }

    &:focus::before {
      background: $color-brand-primary;
      border-radius: 5px;
      bottom: -3px;
      content: '';
      left: -3px;
      position: absolute;
      right: -3px;
      top: -3px;
      z-index: 0;
    }

    & img {
      border-radius: 5px;
      display: block;
      height: 48px;
      position: relative;
      width: 48px;
      z-index: 2;
    }
  }


  &__text {
    @media screen and (min-width: 480px) {
      padding-left: 15px;
    }

    padding-left: 10px;

    & h1,
    & h4 {
      margin: 0;
      padding: 0;
      line-height: 1em;
    }

    & h1 {
      @media screen and (min-width: 480px) {
        font-size: 28px;
      }

      font-size: 22px;
    }

    & h4 {
      @media screen and (min-width: 480px) {
        font-size: 16px;
        padding-top: 10px;
      }

      font-size: 14px;
      font-weight: 300;
      line-height: 14px;
      padding-top: 5px;

      & span {
        font-weight: 700;
      }
    }

    .spinner {
      width: 16px;
      height: 16px;
      vertical-align: bottom;
    }
  }
}
