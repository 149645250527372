@import 'src/styles/colors.module';

$icon-size: 25px;

.mycrew-tabbar {
  background: $color-background-inner;
  border-top: 3px solid $color-background;
  bottom: -1px;
  font-size: 0;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 10;

  &:before {
    border-radius: 50%;
    border-top: 3px solid $color-brand-primary;
    content: '';
    position: absolute;
    top: -3px;
    transform: translateX(2.15 * -100%);
    transition: all cubic-bezier(0.68,-0.55,0.27,1.55) 400ms;
    width: 10%;
  }

  //&:after {
  //  border-left: 5px solid transparent;
  //  border-right: 5px solid transparent;
  //  border-top: 0 solid $color-brand-primary;
  //  content: '';
  //  position: absolute;
  //  animation: activeTab 1000ms ease-in-out forwards;
  //  top: 0;
  //  transform: translateX(2.15 * -92.5%);
  //  transition: all cubic-bezier(0.68,-0.55,0.27,1.55) 400ms;
  //}

  &.home:before {
    left: 33.33%;
  }

  //&.home:after {
  //  left: 20.5%;
  //}

  &.search:before {
    left: 66.67%;
  }

  //&.search:after {
  //  left: 53.67%;
  //}

  &.my-profile:before {
    left: 100%;
  }

  //&.my-profile:after {
  //  left: 87%;
  //}

  //&.team:before {
  //  border-radius: 0;
  //  border-width: 3px;
  //  border-top-style: double;
  //  left: 0;
  //  transform: none;
  //  transition: none;
  //  width: 100%;
  //}

  &.user:before,
  &.team:before {
    border-radius: 0;
    border-width: 1px;
    left: 0;
    top: -2px;
    transform: none;
    //transition: none;
    width: 100%;
  }

  &__wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    border-radius: 0;
    text-align: center;
  }

  &__link {
    display: block;
    min-width: 106px;
    overflow: auto;
    padding: 8px 0 5px;
    text-decoration: none;
  }

  &__icon-wrapper {
    align-items: center;
    display: inline-flex;
    height: $icon-size;
    justify-content: center;
    width: $icon-size;
  }

  &__icon,
  &__icon.MuiSvgIcon-root {
    color: $color-white;
    font-size: $icon-size;
  }

  &__label {
    color: $color-gray-70;
    display: block;
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
  }
}

//@keyframes activeTab {
//  0% {
//    border-top-width: 0;
//  }
//  33% {
//    border-top-width: 7px;
//  }
//  66% {
//    border-top-width: 3px;
//  }
//  100% {
//    border-top-width: 5px;
//  }
//}

// Light theme for TabBar
.mycrew-light {
  .mycrew-tabbar {
    background: $color-light-background;
    border-top: 3px solid $color-background-light-gradient;

    &:before {
      border-top: 3px solid $color-light-text-accent;
    }

    &__icon,
    &__icon.MuiSvgIcon-root {
      color: $color-light-text;
      font-size: $icon-size;
    }

    &__label {
      color: $color-light-text;
    }
  }
}
