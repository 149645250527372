@import 'src/styles/colors.module';

.mycrew-overlay {
  background: rgba($color-background-inner, 0.96);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  &__wrapper {
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    height: 100%;
  }
}

// Light theme for OverlayContext
.mycrew-light {
  .mycrew-overlay {
    background: rgba($color-background-light-gradient, 0.96);
  }
}
