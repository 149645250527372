@import 'src/styles/colors.module';

.mycrew-team-menu {
  @media screen and (min-width: 480px) {
    grid-column: auto;
    margin: 0;
    width: calc(100% - 15px);
  }

  grid-column: span 4;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  width: calc(100% + 30px);

  & .flicking-viewport {
    padding: 0;
    margin: 0;
  }

  & .flicking-camera {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    z-index: 1;
  }

  &__wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 70px;
    justify-content: center;
  }

  &__swipe {
    overflow: hidden;

    .flicking-camera .mycrew-team-menu__item {
      &:last-child {
        @media screen and (min-width: 480px) {
          padding-right: 0;
        }

        padding-right: 15px;
      }

      &:first-child {
        @media screen and (min-width: 480px) {
          padding-left: 0;
        }
      }
    }
  }

  &__show-all {
    font-size: 0;
    position: relative;
    text-align: center;
    width: 100%;

    &:before {
      background: $color-off-white;
      box-shadow: -3px 0 5px 3px rgba($color-background-inner, 1);
      content: '';
      height: 125%;
      left: 0;
      position: absolute;
      top: -1px;
      width: 1px;
      z-index: 999;
    }
  }

  &__show-all-button {
    border-radius: 5px;
    display: block;
    height: 36px;
    text-transform: none;
  }

  &__show-all-icon {
    display: block;
    line-height: 1em;
    transform: scale(0.9);
    vertical-align: middle;
    color: $color-white;
  }

  &__show-all-label {
    display: block;
    font-size: 10px;
    line-height: 1em;
    padding-bottom: 1px;
    letter-spacing: 0.05em;
    vertical-align: middle;
  }

  & ul {
    @media screen and (min-width: 480px) {
      cursor: pointer;
      overflow-x: auto;
      overflow-y: auto;
      white-space: normal;
    }

    cursor: grab;
    list-style: none;
    margin: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0;
    white-space: nowrap;
  }

  & .mycrew-team-menu__item {
    @media screen and (min-width: 480px) {
      margin: 0;
    }

    display: inline-block;
    padding: 0 0 0 15px;

    & a {
      @media screen and (min-width: 480px) {
        padding: 4px 12px;
      }

      border-radius: 5px;
      border: 1px solid rgba($color-text, 0.5);
      color: $color-text;
      display: block;
      font-size: 1.1em;
      font-weight: 500;
      padding: 4px 12px;
      white-space: nowrap;

      &:hover,
      &:focus {
        outline: none;
        background: rgba($color-brand-primary, 0.3);
        border: 1px solid $color-text;
      }

      &:active {
        background: rgba($color-brand-primary, 0.5);
        border: 1px solid $color-brand-secondary;
      }
    }

    &.loading {
      padding: 5px 0;

      & .spinner {
        height: 16px;
        vertical-align: bottom;
      }
    }
  }
}

// Light theme for TeamMenu
.mycrew-light {
  .mycrew-team-menu {
    &__show-all {
      &:before {
        background: $color-light-text;
        box-shadow: -3px 0 5px 3px rgba($color-background-light-gradient, 1);
      }
    }

    a {
      border-color: $color-light-text;
      color: $color-light-text;

      &:hover,
      &:focus {
        outline: none;
        background: rgba($color-switch-toggle, 0.3);
        border: 1px solid $color-light-text-accent;
      }

      &:active {
        background: rgba($color-switch-toggle, 0.5);
        border: 1px solid $color-white;
        color: $color-white;
      }
    }

    &__show-all-icon {
      color: $color-light-text-accent;
    }
  }
}
