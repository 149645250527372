@import 'src/styles/colors.module';

.mycrew-user-panel {
  @media screen and (min-width: 480px) {
    display: block;
    font-size: 0;
  }

  display: block;
  text-align: right;

  &__menu {
    display: flex;
    justify-content: flex-end;
  }

  &__user {}

  &__toggle,
  &__role,
  &__logout,
  &__avatar,
  &__welcome {
    display: inline-block;
    vertical-align: top;
  }

  &__toggle {
    opacity: 0.25;
    transition: opacity 160ms ease-in-out;

    &:hover {
      opacity: 1;

      .toggle-icon {
        &__sun {
          transform: scale(1);
        }

        &__moon {
          transform: scale(1.2) rotate(150deg);
        }
      }
    }

    .switch,
    .circle {
      height: 24px;
      -webkit-transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
      transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
    }

    .switch {
      background: $color-switch-toggle-night;
      border-radius: 12px;
      border: 1px solid rgba($color-off-white, 0.7);
      cursor: pointer;
      height: 21px;
      margin: 0;
      width: 43px;

      &.switched {
        background: $color-switch-toggle-yellow;
        border-color: $color-switch-toggle;

        & .circle {
          background: $color-switch-white;
          margin-left: 55%;
        }
      }
    }

    .circle {
      background: $color-switch-white;
      border-radius: 50%;
      box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
      display: block;
      height: 80%;
      margin-left: 5%;
      margin-top: 5%;
      overflow: hidden;
      width: 40%;
    }

    &-icon.mycrew-user-panel__toggle-icon {
      border-radius: 50%;
      transition: all 100ms ease-in-out;

      &__sun {
        color: $color-switch-sun;
        height: 100%;
        margin: .5px 0 0 0;
        width: 100%;
      }

      &__moon {
        color: $color-background-inner-gradient;
        height: 95%;
        margin: 1px 1px 0 0;
        transform: rotate(150deg);
        width: 95%;
      }
    }
  }

  &__role {
    font-size: 12px;
    line-height: 24px;
    padding: 0 0 0 15px;
    text-transform: capitalize;

    &-icon.MuiSvgIcon-root {
      font-size: 20px;
      line-height: 1em;
      margin-right: 5px;
      vertical-align: -6px;
    }
  }

  &__logout {
    &.MuiLink-button {
      font-size: 12px;
      line-height: 24px;
      padding: 0 0 0 15px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }

    &.MuiLink-root {
      color: $color-background;

      &:hover {
        color: $color-white;
      }
    }

    &-icon.MuiSvgIcon-root {
      font-size: 20px;
      line-height: 1em;
      margin-right: 5px;
      vertical-align: -6px;
    }
  }

  &__avatar {
    @media screen and (min-width: 480px) {
      margin-top: 2px;
      margin-left: 10px;
    }

    background: $color-background;
    border-radius: 3px;
    border: 1px solid $color-background;
    margin-top: 5px;
    overflow: hidden;
    position: relative;

    .MuiTypography-root {
      display: flex;
    }
  }

  &__welcome {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1em;
    vertical-align: bottom;
  }
}

.mycrew.mycrew-light {
  .mycrew-user-panel__logout.MuiLink-root {
    color: $color-switch-toggle;

    &:hover {
      color: $color-light-text-accent;
    }
  }
}
