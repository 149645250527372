@import 'src/styles/colors.module';
@import 'src/styles/common';
@import 'src/styles/mixins';

.mycrew-all-search {
  @media screen and (min-width: 480px) {
    grid-column: auto;
  }

  grid-column: span 4;
  position: relative;

  &__input {
    @media screen and (min-width: 480px) {
      border-radius: 30px;
      font-size: 1em;
      height: 100%;
      padding: 0 15px;
    }

    background: rgba($color-background, 0.4);
    border-radius: 23px;
    color: $color-text;
    font-size: 1.1em;
    height: 46px;
    padding: 0 23px;
    width: 100%;

    & input {
      color: $color-text;
    }

    &:before,
    &:after {
      display: none;
    }

    &:hover {
      background: rgba($color-background, 0.7);

      & + .mycrew-all-search__input--search-icon {
        opacity: 0.5;
      }
    }

    &:active,
    &:focus,
    &:focus-within,
    &--active,
    &--active:hover {
      background: $color-background;

      & + .mycrew-all-search__input--search-icon {
        opacity: 0.5;
      }
    }

    &--search-icon {
      @media screen and (min-width: 480px) {
        right: 10px;
        top: 6px;
        transform: scale(0.9);
      }

      opacity: 0.3;
      position: absolute;
      right: 15px;
      top: 11px;
      transform: scale(1.2);
    }

    &--clear {
      @media screen and (min-width: 480px) {
        right: 5px;
        top: 4px;
      }

      background: rgba($color-background-inner, 0.7);
      border-radius: 50%;
      height: 23px;
      line-height: 1em;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      right: 17px;
      top: 11px;
      width: 23px;
      z-index: 1;

      & &-icon {
        color: rgba($color-white, 0.2);
        height: 19px;
        position: relative;
        top: 2px;
      }

      &:hover {
        background: rgba($color-background-inner, 0.1);

        &:before {
          border-radius: 50%;
          border: 1px solid rgba($color-background-inner, 0.6);
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
        }
      }

      &:hover &-icon {
        color: rgba($color-white, 0.75);
      }
    }
  }

  &__results {
    @media screen and (min-width: 480px) {
      left: 15px;
      right: 15px;
    }

    background: $color-background;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    -webkit-box-shadow: 5px 10px 30px 3px rgba(0, 0, 0, 1);
    box-shadow: 0 17px 15px 0 rgba(0, 0, 0, 0.5);
    left: 21px;
    max-height: 408px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0 0 15px 0;
    position: absolute;
    right: 21px;
    z-index: 100;

    &--items {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &--items {
      & .mycrew-all-search__results--link {
        color: $color-text;
      }

      & li {
        &:last-child {
          & .mycrew-all-search__results--link::after {
            border-bottom: none;
          }
        }
      }
    }

    &--link {
      display: block;
      outline: none;
      padding: 10px 0 10px 12px;
      position: relative;

      &:hover,
      &:focus {
        background: rgba($color-brand-primary, 0.3);
      }

      &::after {
        border-bottom: 1px solid rgba($color-white, 0.1);
        bottom: 0;
        content: '';
        left: 5px;
        position: absolute;
        right: 5px;
      }

      & .image {
        display: block;
        overflow: auto;
        border-radius: 5px;
        height: 48px;
        width: 48px;
        background: $color-background-inner;
        background: linear-gradient(270deg, $color-background, $color-background-inner, $color-background);
        background-size: 500% 500%;
        animation: loadingGradient 3s linear infinite;

        & img {
          border-radius: 5px;
          display: block;
          height: 48px;
          width: 48px;
        }
      }

      & .name {
        bottom: 11px;
        display: block;
        left: 70px;
        font-weight: 500;
        position: absolute;
        font-size: 1.05em;
        right: 0;
        line-height: 1em;
        white-space: nowrap;
      }

      // List results for Teams
      & .team,
      & .team-abbr {
        border-radius: 3px;
        border: 1px solid rgba($color-white, 0.5);
        display: inline-block;
        font-size: 1.1em;
        font-weight: 500;
        margin: 3px 0 0 0;
        padding: 2px 6px;
        position: relative;
        vertical-align: top;
      }

      // List results for Users
      & .name + .team {
        border-radius: 3px;
        color: rgba($color-text, 0.75);
        border: 1px solid rgba($color-white, 0.5);
        display: block;
        font-size: 0.7em;
        padding: 1px 5px;
        position: absolute;
        right: 15px;
        top: 7px;
      }

      & .team-abbr {
        box-sizing: border-box;
        margin-top: 0;
        text-align: center;
        text-transform: uppercase;
        width: 48px;
      }

      & .team-full {
        display: inline-block;
        font-size: 0.84em;
        padding-left: 13px;
        vertical-align: -0.2em;
        width: calc(100% - 61px);
      }
    }

    &--none {
      & .label {
        background-color: $color-background;
        color: $color-background-inner;
        display: block;
        font-size: 1.15em;
        font-weight: 700;
        padding-top: 10px;
        text-align: center;
        text-shadow: rgba($color-white, .21) 1px 1px 0;
      }
    }
  }
}

.mycrew-light .mycrew-all-search {
  &__input {
    background: rgba($color-light-all-search, 0.4);

    & input {
      color: $color-light-text;
    }

    &:hover {
      background: rgba($color-light-all-search, 0.7);
    }

    &:active,
    &:focus,
    &:focus-within,
    &--active,
    &--active:hover {
      background: $color-light-all-search;
    }

    &--clear {
      background: $color-switch-toggle-yellow;

      &:hover {
        background: rgba($color-switch-toggle-yellow, 0);

        &:before {
          border: 1px solid $color-switch-toggle;
        }

        .mycrew-all-search__input--clear-icon {
          color: $color-switch-toggle-yellow;
        }
      }

      &-icon {
        color: rgba($color-white, 0.75);
      }
    }
  }

  &__results {
    background: $color-light-all-search;

    &--users,
    &--teams {
      & .mycrew-all-search__results--link {
        color: $color-light-text;
      }
    }

    &--link {
      &::after {
        border-bottom: 1px solid rgba($color-light-text, 0.1);
      }

      &:hover,
      &:focus {
        background: rgba($color-light-background, 0.75);
      }

      & .image {
        background: $color-light-all-search;
        background: linear-gradient(270deg, $color-light-all-search, rgba($color-light-text, 0.5), $color-light-all-search);
        background-size: 500% 500%;
      }

      & .team {
        border: 1px solid rgba($color-light-text, 0.5);
      }

      & .name {
        color: rgba($color-light-text, 1);
      }

      // List results for Users
      & .name + .team {
        color: rgba($color-light-text, 0.75);
        border: 1px solid rgba($color-light-text, 0.5);
      }
    }

    &--none {
      & .label {
        background-color: $color-light-all-search;
        color: $color-switch-toggle-yellow;
      }
    }
  }
}

@keyframes loadingGradient {
  0%{background-position: 0 50%}
  100%{background-position: -500% 50%}
}
