@import 'src/styles/common';
@import 'src/styles/colors.module';
@import 'src/styles/mixins';

$arrow-width: 9px;

.mycrew-user-page {
  @media screen and (min-width: 480px) {
    display: grid;
    grid-template-columns: 260px 1fr 360px;
  }

  padding: 30px 0;

  &__photo {
    @media screen and (min-width: 480px) {
      @include transition-fix;

      background: rgba($color-white, 0.1);
      border-radius: 35% 65% 90% 10% / 90% 65% 35% 10%;
      box-shadow: 5px 5px 0 0 rgba($color-white, 0.2);
      -webkit-box-shadow: 5px 5px 0 0 rgba($color-white, 0.2);
      height: 200px;
      margin-left: 60px;
      overflow: hidden;
      transition: all 450ms ease-in-out;
      width: 200px;
    }

    @include transition-fix;

    background: $logo-animated center center no-repeat rgba($color-white, 0.1);
    background-size: cover;
    border-radius: 35% 65% 90% 10% / 90% 65% 35% 10%;
    box-shadow: 5px 5px 0 0 rgba($color-white, 0.2);
    -webkit-box-shadow: 5px 5px 0 0 rgba($color-white, 0.2);
    height: 320px;
    margin: 0 auto;
    overflow: hidden;
    transition: all 450ms ease-in-out;
    width: 320px;

    &:hover {
      border-radius: 20% 80% 35% 65% / 80% 25% 75% 20%;
      border-radius: 23% 77% 47% 53% / 75% 39% 61% 25%;

      & img {
        transform: rotate(5deg) scale(1.1);
      }

      .mycrew-user-page__photo-options {
        background: rgba($color-background-inner, 0.7);

        &-label {
          &--icon {
            animation: pop-and-zoom-out 250ms forwards linear;
          }
        }

        &--cta {
          opacity: 0.9;
          transition: all 200ms ease-in;
          transition-delay: 200ms;
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
      }
    }

    & img {
      transition: all 450ms ease-in-out;
    }

    &-options {
      bottom: 0;
      cursor: pointer;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      transition: all 500ms ease-in-out;
      vertical-align: middle;
      width: 100%;

      &-label {
        bottom: 24px;
        display: block;
        left: 24px;
        position: fixed;

        &--icon {
          align-items: center;
          animation: pop-and-zoom-in 400ms forwards ease-out;
          display: inline-flex;
          height: 30px;
          justify-content: center;
          position: relative;
          width: 30px;

          & .label-icon {
            color: $color-white;
            transform: scale(0.84);
          }
        }
      }

      &--cta {
        color: $color-white;
        display: inline-block;
        font-size: 0.9em;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateX(-115%) translateY(75%) scale(0.1);
        transition: all 250ms ease-in;

        & .button-icon {
          transform: scale(1.25);
        }
      }

      &--title {
        font-weight: 700;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__creds {
    @media screen and (min-width: 480px) {
      min-height: 230px;
      margin-left: 60px;
    }

    min-height: 230px;
    overflow: hidden;

    & h1 {
      font-weight: 300;
      text-align: center;

      & small {
        @media screen and (min-width: 480px) {
          padding-right: 29px;
        }

        display: block;
        font-size: 0.55em;
        letter-spacing: 0.2em;
        line-height: 1.5em;
        margin-top: 30px;
        text-align: right;

        .mycrew-user-page__creds-team-link {
          box-shadow: inset 0 0 $color-brand-primary;
          color: $color-white;
          position: relative;
          text-decoration: none;
          letter-spacing: 1px;
          padding: 0 2px;
          margin-left: -5px;
          transition: all ease-in-out 100ms;

          &:hover {
            box-shadow: inset 0 -1.25em $color-brand-primary;
          }

          &:after {
            background: $color-brand-primary;
            border-radius: 50%;
            bottom: 6px;
            content: '';
            height: 7px;
            position: absolute;
            right: -12px;
            width: 7px;
            z-index: -1;
          }

          &:hover:after {
            animation: animate-team-link 600ms forwards ease-out;
          }
        }
      }
    }
  }

  &__external {
    @media screen and (min-width: 480px) {
      border-left: 1px solid rgba($color-white, 0.2);
      padding: 15px 0 15px 29px;
    }

    padding: 15px 0 30px;

    &-empty {
      @media screen and (min-width: 480px) {
        text-align: left;
      }

      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      opacity: 0.5;
      text-align: center;
    }

    &-links {
      margin: 0;
      padding: 0;
    }

    & li {
      list-style: none;
    }

    &-item {
      display: inline-block;
      font-size: 0.9em;
      line-height: 1.6em;
      margin: 0 15px 0 0;
      padding: 0 0 12px 0;
      position: relative;
      width: calc(50% - 7.5px);

      &:nth-child(2n+2) {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & .external-item {
        &__label {
          color: rgba($color-text, 0.5);
          display: block;
          font-size: 0.7em;
          left: 0;
          letter-spacing: 0.05em;
          line-height: 1.2em;
          text-transform: uppercase;

          &:hover {
            .external-item__button {
              opacity: 1;
            }
          }
        }

        &__button {
          cursor: pointer;
          opacity: 0.8;
          position: absolute;
          right: 0;

          &:hover {
            &:before {
              width: 22px;
              height: 22px;
              display: block;
              content: '';
              position: absolute;
              z-index: 0;
              border-radius: 3px;
            }
          }

          &--delete {
            top: -4px;

            & .external-item__button--icon {
              color: rgba($color-white, 0.5);
              height: 18px;
            }

            &:hover {
              & .external-item__button--icon {
                color: rgba($color-white, 0.8);
              }

              &:before {
                top: -2px;
                left: 1px;
                background: rgba($color-red, 0.5);
              }
            }

            &:active {
              & .external-item__button--icon {
                color: rgba($color-white, 1);
              }
            }
          }

          & .external-item__button--icon {
            position: relative;
            z-index: 1;
          }

          &--edit {
            top: -2px;
            transform: translateX(-100%);

            & .external-item__button--icon {
              color: rgba($color-white, 0.5);
              height: 14px;
            }

            &:hover {
              & .external-item__button--icon {
                color: rgba($color-white, 0.8);
              }

              &:before {
                top: -4px;
                left: 1px;
                background: rgba($color-background, 0.5);
              }
            }

            &:active {
              & .external-item__button--icon {
                color: rgba($color-white, 1);
              }
            }
          }

          &--icon {
            color: rgba($color-white, 0.5);
          }
        }

        &__link {
          color: rgba($color-white, 1);
          display: inline-block;
          margin-left: 15px;
          padding: 1px 0 4px;

          &:hover {
            color: rgba($color-white, 0.8);
          }

          &:active {
            color: rgba($color-white, 0.5);
          }
        }
      }
    }

    & &-add {
      display: block;
      margin-right: 0;
      margin-top: 15px;
      padding: 0;
      text-align: center;
      width: 100%;

      &--button {
        border-radius: 5px;
        border: 2px dashed rgba($color-white, 0.3);
        color: rgba($color-white, 0.3);
        display: inline-block;
        font-size: 1em;
        margin: 0 auto;
        padding: 10px 0 8px 18px;
        position: relative;
        text-align: center;
        width: 50%;

        &:hover {
          background: rgba($color-background, 0.15);
          border: 2px dashed rgba($color-white, 0.5);
          color: rgba($color-white, 0.5);
          text-decoration: none;

          & .button-icon {
            transform: rotate(90deg) scale(1.05);
          }
        }

        &:active {
          border: 2px dashed rgba($color-white, 0.8);
          color: rgba($color-white, 0.8);
        }

        & .button-icon {
          height: 20px;
          left: 10px;
          position: absolute;
          top: 9px;
          transform: scale(0.95);
          transition: all 250ms ease-in-out;
          vertical-align: top;
        }
      }
    }
  }

  &__about {
    grid-column: auto / span 3;
    margin-top: 30px;
    border-radius: 15px;
    border: 2px solid rgba($color-background, 0.3);
    padding: 25px 30px 35px 30px;
    position: relative;
    font-size: 1.084em;

    &:hover,
    &.editor-active {
      background: rgba($color-background, 0.1);

      & h3:after {
        border: 2px solid rgba($color-background, 0.3);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top: 0;
        bottom: 0;
        content: '';
        height: calc(50% + 4px);
        left: 0;
        position: absolute;
        right: 0;
      }
    }

    & h3,
    &-edit,
    &-cancel {
      background: $color-background-inner;
      border-radius: 12px;
      color: rgba($color-text, 0.4);
      font-weight: 500;
      line-height: 1em;
      margin: 0;
      font-size: 0.84em;
      letter-spacing: 0.1em;
      padding: 0 15px 8px 15px;
      position: absolute;
      left: 20px;
      top: 4px;
      transform: translateY(-50%);
    }

    &-counter {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      font-size: 0.84em;

      &--icon {
        vertical-align: bottom;
        margin-left: 2px;

        &.ok,
        &.error {
          height: 18px;
          vertical-align: top;
          color: $color-green;
        }

        &.error {
          color: $color-red;
        }
      }
    }

    &-edit,
    &-cancel {
      border-radius: 6px;
      border: 2px solid rgba($color-background, 0.3);
      bottom: 0;
      color: rgba($color-text, 0.4);
      cursor: pointer;
      display: inline-block;
      left: initial;
      padding: 5px 40px 5px 12px;
      right: 20px;
      text-decoration: none;
      top: initial;
      transform: translateY(50%);

      & &--icon {
        height: 16px;
        position: absolute;
        right: 7px;
        top: 3px;

        & .spinner {
          height: 14px;
        }
      }

      &:hover {
        border-color: rgba($color-background, 0.5);
        color: rgba($color-text, 0.8);
      }
    }

    &-cancel {
      transform: translateY(50%) translateX(-100%);
    }

    &-editor {
      box-sizing: border-box;
      color: rgba($color-text, 0.5);
      font-size: 0.84em;
      line-height: 2em;
      margin: 0 0 10px 0;
      outline: none;
      padding-bottom: 15px;
      position: relative;
      transition: color 300ms ease-in-out;
      width: 100%;

      &:hover {
        color: $color-text;
      }

      &--active {
        color: $color-text;
      }
    }
  }
}

.mycrew-light .mycrew-user-page {
  &__creds {
    & h1 {
      & small {
        .mycrew-user-page__creds-team-link {
          color: $color-light-text-accent;

          &:hover {
            color: $color-white;
            box-shadow: inset 0 -1.25em $color-brand-primary;
          }
        }
      }
    }
  }

  &__about {
    border: 2px solid rgba($color-light-text-accent, 0.3);

    &:hover,
    &.editor-active {
      background: rgba($color-light-text-accent, 0.1);

      & h3:after {
        border: 2px solid rgba($color-light-text-accent, 0.3);
        border-top: 0;
      }
    }

    & h3,
    &-edit,
    &-cancel {
      background: $color-light-background;
      color: rgba($color-light-text, 0.75);
    }

    &-counter {
      &--icon {
        position: relative;

        &::after {
          @include absolute-content;

          top: 50%;
          right: 50%;
          z-index: 1;
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background: rgba($color-light-background, 0.84);
          transform: translateX(53%) translateY(-50%);
        }

        &.ok,
        &.error {
          z-index: 2;
        }

        &.ok {
          color: $color-light-green;
        }
      }
    }

    &-edit,
    &-cancel {
      border: 2px solid rgba($color-light-text-accent, 0.3);
      color: rgba($color-light-text, 0.4);

      &:hover {
        border-color: rgba($color-light-text-accent, 0.5);
        color: rgba($color-light-text, 0.8);
      }
    }

    &-editor {
      color: rgba($color-light-text, 0.75);

      &:hover {
        color: $color-light-text;
      }

      &--active {
        color: $color-light-text;
      }
    }
  }

  &__external {
    @media screen and (min-width: 480px) {
      border-left: 1px solid rgba($color-light-text, 0.3);
    }

    border-left: none;

    &-item {
      & .external-item {
        &__label {
          color: rgba($color-light-text, 0.75);
        }

        &__button {
          &--delete {
            & .external-item__button--icon {
              color: rgba($color-light-text, 0.5);
            }

            &:hover {
              & .external-item__button--icon {
                color: rgba($color-white, 0.8);
              }

              &:before {
                background: rgba($color-red, 0.5);
              }
            }

            &:active {
              & .external-item__button--icon {
                color: rgba($color-light-text, 1);
              }
            }
          }

          &--edit {
            & .external-item__button--icon {
              color: rgba($color-light-text, 0.5);
            }

            &:hover {
              & .external-item__button--icon {
                color: rgba($color-white, 0.8);
              }

              &:before {
                background: rgba($color-light-text-accent, 0.5);
              }
            }

            &:active {
              & .external-item__button--icon {
                color: rgba($color-light-text, 1);
              }
            }
          }

          &--icon {
            color: rgba($color-light-text, 0.5);
          }
        }

        &__link {
          color: rgba($color-light-text, 1);

          &:hover {
            color: rgba($color-light-text, 0.8);
          }

          &:active {
            color: rgba($color-light-text, 0.5);
          }
        }
      }
    }

    &-add {
      &--button {
        border: 2px dashed rgba($color-light-text, 0.3);
        color: rgba($color-light-text, 0.3);

        &:hover {
          background: rgba($color-light-text-accent, 0.15);
          border: 2px dashed rgba($color-light-text, 0.5);
          color: rgba($color-light-text, 0.5);
        }

        &:active {
          border: 2px dashed rgba($color-light-text, 0.8);
          color: rgba($color-light-text, 0.8);
        }
      }
    }
  }
}

@keyframes pop-and-zoom-out {
  0% {
    background: rgba($color-background-gradient, 0.7);
    border-radius: 7px;
    opacity: 1;
  }
  10% {
    background: rgba($color-background-gradient, 1);
    opacity: 1;
  }
  20% {
    transform: scale(0.84);
  }
  50% {
    transform: scale(0.76);
  }
  70% {
    border-radius: 50%;
    transform: scale(1.2);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0) translateY(24px) translateX(24px);
  }
}

@keyframes pop-and-zoom-in {
  0% {
    border-radius: 50%;
    opacity: 0;
    transform: scale(0) translateY(24px) translateX(24px);
  }
  10% {
    opacity: 1;
  }
  30% {
    border-radius: 50%;
    transform: scale(0.76);
  }
  50% {
    transform: scale(1.2);
  }
  80% {
    background: rgba($color-background-gradient, 1);
    border-radius: 7px;
    transform: scale(0.84);
  }
  90% {
    opacity: 1;
  }
  100% {
    background: rgba($color-background-gradient, 0.7);
    border-radius: 7px;
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
  }
}

@keyframes animate-team-link {
  0% {
    background: $color-brand-primary;
    border-radius: 50%;
    bottom: 6px;
    content: '';
    height: 7px;
    opacity: 1;
    position: absolute;
    right: -12px;
    width: 7px;
  }
  15% {
    opacity: 1;
    transform: scale(1.5);
  }
  50% {
    background: $color-brand-primary;
    border-radius: 50%;
    border: none;
    bottom: 7px;
    height: 7px;
    opacity: 0;
    transform: translateX(-20px) scale(2);
    width: 7px;
  }
  51% {
    background: none;
    border-bottom: $arrow-width solid transparent;
    border-left: $arrow-width solid $color-brand-primary;
    border-radius: 1px;
    border-top: $arrow-width solid transparent;
    bottom: 2px;
    height: 0;
    opacity: 1;
    transform: translateX(-20px) scale(0.5);
    width: 0;
  }
  60% {
    background: none;
    border-bottom: $arrow-width solid transparent;
    border-left: $arrow-width solid $color-brand-primary;
    border-radius: 1px;
    border-top: $arrow-width solid transparent;
    bottom: 2px;
    height: 0;
    opacity: 1;
    transform: translateX(9px) scale(1);
    width: 0;
  }
  70% {
    background: none;
    border-bottom: $arrow-width solid transparent;
    border-left: $arrow-width solid $color-brand-primary;
    border-radius: 1px;
    border-top: $arrow-width solid transparent;
    bottom: 2px;
    height: 0;
    opacity: 1;
    transform: translateX(5px) scale(1);
    width: 0;
  }
  85% {
    transform: translateX(0px) scale(1);
  }
  100% {
    background: none;
    border-bottom: $arrow-width solid transparent;
    border-left: $arrow-width solid $color-brand-primary;
    border-radius: 1px;
    border-top: $arrow-width solid transparent;
    bottom: 2px;
    height: 0;
    opacity: 1;
    transform: translateX(2px) scale(1);
    width: 0;
  }
}
